const QuizQuestion = (props) => {
  
  const { question, options, onOptionClicked, currentQuestionAnswer } = props;
 
  return (
    <>
      <div className="text-xl text-center">{question}</div>
      <div className="flex flex-col items-center pt-4 m-4 cursor-pointer tap-bg-transparent focus:outline-none">
        {options.map((el, index) => {
          return (
            <div
              onClick={() => onOptionClicked(index)}
              key={index}
              className={"bg-white w-full xs:w-3/4 lg:w-1/2 border-2 border-gray-200 text-center rounded-lg shadow-md m-2 p-3 cursor-pointer " + ( currentQuestionAnswer === index ? 'shadow-red-500 border-red-200' : '')}
            >
              <h3 className="text-lg ">{el}</h3>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default QuizQuestion;
