import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import QuizRoom  from './pages/quizRoom';
import ContactUs from "./pages/contactUs";
import TermsConditions from './pages/terms-conditions';
import PrivacyPolicy from './pages/privacy-policy';
import NotFound from './pages/404';

function App() {
  return (
      <div className="h-auto min-h-screen w-screen " style={{ backgroundColor: '#f8fafc'}}>
        <Router>
          <Routes>  
            <Route exact path="/" element={<QuizRoom/>}/>  
            <Route exact path="/:topic" element={<QuizRoom/>}/>  
            <Route exact path="/contact-us" element={<ContactUs/>}/>  
            <Route exact path="/terms-conditions" element={<TermsConditions/>}/>  
            <Route exact path="/privacy-policy" element={<PrivacyPolicy/>}/>  
            <Route exact path="*" element={<NotFound/>}/>  
          </Routes>
        </Router>
      </div>
  );
}

export default App;
