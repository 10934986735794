import { AiFillCopy } from 'react-icons/ai';

const CopyButton = (props) => {
  const { onCopyShareLink } = props;
  return (
    <>
      <button
        onClick={onCopyShareLink}
        className="w-auto xs:w-1/3 flex items-center justify-center bg-transparent hover:bg-blue-500 text-blue-500 hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded"
      >
        <AiFillCopy/>
        Copy
      </button>
    </>
  );
};

export default CopyButton;
