import { io } from "socket.io-client";
import { useEffect, useState, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import QuizQuestion from "../components/share/quizQuestion";
import Loader from "../components/share/loader";
import { QuizButton, GreenButton } from "../components/share/buttons/appButton";
import FooterLink from "../components/share/footerLink";
import { useParams } from 'react-router-dom';

import { ToastContainer, toast } from "react-toastify";

import PlayerNameLists from "../components/share/playersNameList";
import CopyButton from "../components/share/buttons/copyButton";
import PlayersScoreList from "../components/share/playersScoreLists";

import { FaShare } from "react-icons/fa";

import LangButtonGroups from "../components/share/langButtonGroups";
import AppHeader from "../components/share/header";
import GameManual from "../components/quizRoom/gameManual";
import LoggerService from "../services/logger";
import { redirectToHome } from "../utils/urlUtils";
import TimeCounter from "../components/share/timeCounter";

function QuizRoom() {

  const { topic: routeTopic } = useParams();

  const [backendServiceLink] = useState('https://quizfleet-be.onrender.com')

  const [isCopied, setIsCopied] = useState(false);

  const [userSocket, setUserSocket] = useState(null);

  const [userName, setUserName] = useState("");
  const [roomId, setRoomId] = useState(null);
  const [socketId, setSocketId] = useState(null);
  const [invitedUser, setInvitedUser] = useState(false);

  const [playersList, setPlayersList] = useState([]);
  const [gameStarted, setGameStarted] = useState(false);
  const [adminName, setAdminName] = useState(null);

  const [timeCount, setTimeCount] = useState(null);
  const [currentQuestionNo, setCurrentQuestionNo] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentQuestionAnswer, setCurrentQuestionAnswer] = useState(null);
  const [gameOver, setGameOver] = useState(false);
  const [finalResult, setFinalResult] = useState(null);

  const [displayLoader, setDisplayLoader] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('')

  const [langType, setLangType] = useState("en");

  const scrollContainerRef = useRef(null);
  const [topics] = useState([
    { label: 'ALL', value: 'ALL' },
    { label: 'IPL 2024(Final Match)', value: 'ipl-2024-final-match', exclusive: true},
    { label: 'India', value: 'india' },
    { label: 'World', value: 'world' },
    { label: 'Cricket', value: 'cricket' },
    { label: 'Bollywood', value: 'bollywood' },
    { label: 'Indian Polity', value: 'indianPolity' },
    { label: 'Indian Culture', value: 'indianCulture' },
    { label: 'Science', value: 'science' },
    { label: 'Technology', value: 'technology' },
    { label: 'Wildlife', value: 'wildlife' },
    { label: 'Geography', value: 'geography' }
  ]);

  const scrollToItem = (index) => {
    if (scrollContainerRef.current) {
      const item = scrollContainerRef.current.children[index];
      item.scrollIntoView({ behavior: 'smooth', block: 'nearest', });
    }
  };

  const [selectedTopicIndex, setSelectedTopicIndex] = useState(0)

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const roomId = queryParams.get("r");
    if (roomId && !socketId) {
      // LoggerService.log("Setting room id");
      setRoomId(roomId);
      setInvitedUser(true);
      startConnection();
    }
  }, []);

  useEffect(()=>{
    if(routeTopic){
      const index = topics.findIndex((el, index) => el.value === routeTopic)
      if(index !== -1){
        setSelectedTopicIndex(index)
      }
      
    }
  }, [routeTopic])

  useEffect(() => {
    // setting loader to false, when time count is not null, instead of
    // getQuestion listner, otherwise it would not be displayed for the moment time count is there
    if (timeCount) setDisplayLoader(false);
    if (timeCount === 0) {
      // LoggerService.log("count is 0", timeCount);
      // display loader for next question
      setDisplayLoader(true);
      setLoadingMessage(currentQuestionNo === 4 ? 'Quiz over' : 'Next question...')

      // set current question to null
      setCurrentQuestion(null);

      // set last attemmpted(if) to null
      setCurrentQuestionAnswer(null);

      //send answer response to server
      userSocket.emit("questionAttempt", {
        roomId,
        qNo: currentQuestionNo,
        answer: currentQuestionAnswer,
      });

      // setcount to null
      setTimeCount(null);

      // LoggerService.log("roomId", roomId);
      setCurrentQuestionNo(currentQuestionNo + 1);
    }
  }, [timeCount]);

  useEffect(() => {
    if (!userSocket) return;

    userSocket.on("connect", () => {
      // LoggerService.log("user socket id", userSocket.id);
      setSocketId(userSocket.id);
      setDisplayLoader(false);
    });

    userSocket.on("inValidLink", (data) => {
      // LoggerService.log("Invalid link event listen");
      const { message } = data;
      displayMessage(message, "error");
      setDisplayLoader(false)
      setLoadingMessage('')

      // Redirect to root page
      setTimeout(() => {
        redirectToHome()
      }, 3000);
    });

    userSocket.on("userLeft", (data) => {
      // LoggerService.log("userLeft");
      const { message, players } = data;
      // LoggerService.log(players);
      setPlayersList([...players]);
    });

    userSocket.on("createRoom", (data) => {
      const { roomId, message, adminName } = data;
      // LoggerService.log("Room creating");
      // LoggerService.log(roomId);
      setRoomId(roomId);
      setPlayersList([{ name: adminName, isAdmin: true }]);
      setDisplayLoader(false);
    });

    userSocket.on("gameOver", (data) => {
      // LoggerService.log("Game over...");
      const { result } = data;
      // LoggerService.log(result);
      setGameOver(true);
      setFinalResult(result);
      setDisplayLoader(false);

      // De register all the event
      userSocket.off("userJoined");
      userSocket.off("getCount");
      userSocket.off("getQuestion");
      userSocket.off("messages");
      userSocket.off("gameOver");
    });

    userSocket.on("userJoined", (data) => {
      // LoggerService.log(data);
      const { players, adminName } = data;
      setPlayersList([...players]);
      setAdminName(adminName);
      setDisplayLoader(false);
    });

    userSocket.on("getCount", (data) => {
      const { count } = data;
      setTimeCount(count);
    });

    userSocket.on("getQuestion", (data) => {
      if (invitedUser) {
        setDisplayLoader(true);
        setGameStarted(true);
      }

      // LoggerService.log("getQuestion listen");
      // LoggerService.log(data);
      const { question } = data;
      setCurrentQuestion(question);
    });

    userSocket.on("messages", (data) => {
      const { message, type } = data;
      // LoggerService.log(message);
      displayMessage(message, type);
    });

    userSocket.on("closeGame", (data) => {
      // const { message, type } = data;
      // LoggerService.log(message);
      redirectToHome()
    });

    userSocket.on("disconnect", (reason) => {
      // console.log('dissconnection')
      // Refresh the page in dissconnection
      window.location.reload()
      // LoggerService.log("user dsconnected Dissconnected,..", reason);
    });

    return () => {
      userSocket.emit("end");
    };
  }, [userSocket]);

  // Connection to socket is established but not entered to the ROOM yet
  const startConnection = async () => {
    setDisplayLoader(true);
    setLoadingMessage('Connecting...')
    const socketConnection = io(backendServiceLink + "/user", {
      transports: ["websocket"],
    });
    setUserSocket(socketConnection);
  };

  // 1. Called by Admin
  const createRoom = (e) => {
    setDisplayLoader(true);
    setLoadingMessage('Creating')
    e.preventDefault();
    userSocket.emit("createRoom", { name: userName.trim(), langType, topic: topics[selectedTopicIndex]?.value });
  };

  // 2. Called by Invited user
  const joinInvitedUser = (e) => {
    // LoggerService.log("click user joined");
    e.preventDefault();

    setDisplayLoader(true);
    setLoadingMessage('Connecting...')
    userSocket.emit("userJoined", {
      isAdmin: false,
      name: userName,
      roomId: roomId,
    });
  };

  const onCopyShareLink = () => {
    navigator.clipboard.writeText(window.location.href + "?r=" + roomId);
    setIsCopied(true);

    // Hide the flash message after a short delay
    setTimeout(() => {
      setIsCopied(false);
    }, 400);
  };

  const startGame = () => {
    setDisplayLoader(true);
    setLoadingMessage('Starting...')
    LoggerService.log("Start game roomId", roomId);
    setGameStarted(true);
    userSocket.emit("startQuiz", { roomId });
  };

  const onOptionClicked = (ansIndex) => {
    // LoggerService.log(ansIndex);
    setCurrentQuestionAnswer(ansIndex);
  };

  const displayMessage = (message, type) => {
    toast(message, { type: type || "default" });
  };

  const onClickShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "You are invited for quiz.",
          url: `${window.location.href}?r=${roomId}`,
        })
        .then(() => {
          LoggerService.log("Sharing successfull");
        })
        .catch(() => {
          LoggerService.log("Sharing failed");
        });
    }
  };

  const onSelectLang = (lang) => {
    setLangType(lang);
  };

  const onClickTopic = (el, index) => {
    scrollToItem(index)
    if (selectedTopicIndex === index) {
      setSelectedTopicIndex()
      return
    }
    setSelectedTopicIndex(index)
  }

  return (
    <>
      <AppHeader />

      <div
        className="bg-gradient-to-br from-teal-200 via-red-50 to-sky-200"
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          <div className="flex flex-col pt-24">
            <ToastContainer />

            {/* For Loader */}
            {displayLoader && (
              <div>
                <Loader
                  message={loadingMessage}
                />
              </div>
            )}

            {!gameStarted && !displayLoader && (
              <>
                  {routeTopic && selectedTopicIndex !== 0 && <h1 className="text-3xl font-extrabold bg-gradient-to-r 
                    from-orange-500 via-yellow-700 to-red-700 
                    text-transparent bg-clip-text text-center p-4" 
                  >
                    {topics[selectedTopicIndex]?.label}
                  </h1>}
                
                <div>
                  {!socketId && !invitedUser && (
                    <div className="max-w-md w-[90%] mx-auto p-6 bg-white rounded-lg shadow-lg text-center">
                      <QuizButton
                        text="Play Game"
                        onClick={startConnection}
                      />
                      <div className="text-left mb-6">
                        <GameManual />
                      </div>
                    </div>
                  )}
                  {!invitedUser && userSocket && !roomId && (
                    <div className="flex justify-center">
                      <div className="max-w-screen overflow-x-auto whitespace-nowrap hide-scrollbar">
                        <div ref={scrollContainerRef} className="inline-flex space-x-4 p-4">
                          {
                            topics.map((el, index) => {
                              return <div
                                onClick={() => onClickTopic(el, index)}
                                key={index}
                                className={`
                                  cursor-pointer flex-none rounded-lg p-0.5 pl-4 pr-4 border 
                                  border-gray-600 h-8 
                                  ${selectedTopicIndex === index ? 'bg-red-300 font-semibold' : ''}
                                `}>
                                  {el.label}
                                  {el.exclusive && <p className="text-center text-teal-800">Exclusive 🚀</p>}
                                </div>
                            })
                          }
                        </div>
                      </div>
                    </div>
                  )}
                  {!invitedUser && userSocket && !roomId && (
                    <div className="flex flex-wrap pt-3 pb-4 flex-row justify-center">
                      <LangButtonGroups
                        selectedLangType={langType}
                        onSelectLang={onSelectLang}
                      />
                    </div>
                  )}
                  {((invitedUser && playersList.length === 0) || !roomId) &&
                    socketId && (
                      <div className="flex flex-col items-center gap-8 mt-4">
                        <input
                          className="border border-green-500 focus:border-green-500 focus:outline-none rounded-xs px-4 py-2  xs:w-full"
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                          placeholder="Enter your name"
                          type="text"
                          autoFocus
                        />
                        <GreenButton
                          disabled={userName ? false : true}
                          text={invitedUser ? "Join Game" : "Create Game"}
                          onClick={invitedUser ? joinInvitedUser : createRoom}
                        />
                      </div>
                    )}

                  {/* Admin */}
                  {/* Display sharable link when roomId is there, Room is created AND user is not invited  */}
                  {roomId && !invitedUser && (
                    <div className="flex items-center flex-col gap-5">
                      <p className="text-center font-bold">
                        Share this below link
                      </p>
                      <div className="bg-gray-200 p-4 rounded-lg shadow-md text-center mx-4 sm:w-full md:w-2/4">
                        <div className="text-blue-500 font-semibold hover:underline">
                          {window.location.href + "?r=" + roomId}
                        </div>
                      </div>
                      <div className="flex flex-row gap-5 relative mt-5">
                        <CopyButton onCopyShareLink={onCopyShareLink} />
                        <div>
                          <button
                            onClick={onClickShare}
                            className="md:hidden w-auto xs:w-1/3 flex items-center justify-center bg-transparent hover:bg-blue-500 text-blue-500 hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded"
                          >
                            <FaShare />
                            Share
                          </button>
                        </div>
                        {isCopied && (
                          <div className="absolute mt-[-28px] ml-[20px] text-green-500">
                            Copied
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Invited User */}
                  {/* Display this message to the invited user. If the game has not started, user is invited and socket is not null(Connection is established) */}
                  {!gameStarted && invitedUser && adminName && (
                    <div className="text-xl text-center">
                      <p className="text-teal-700">Ask
                      {" "}
                        <span className="text-red-800 font-bold px-2 text-2xl">
                          <b><u>{adminName}</u></b>
                        </span>
                      {" "}
                        to start the game
                      </p>
                    </div>
                  )}

                  {!gameStarted && !invitedUser && roomId && (
                    <div className="mt-7 text-center">
                      <GreenButton onClick={startGame} text="Start Game" />
                    </div>
                  )}

                  {/* Admin and invited User */}
                  {/* Display lists of players when greater than 1 */}
                  {playersList.length > 0 && (
                    <div className="flex flex-col items-center mx-5 mt-4">
                      <div className="flex">
                        <div style={{ width: '45px', height: '45px', marginBottom: '8px', textAlign: 'center', padding: '10px', borderRadius: '50%' }} className="bg-red-400">
                          <img
                            height={"50px"}
                            width={"50px"}
                            src={'./users-group.png'}
                            alt="Players"
                          />
                        </div>
                        <div className="mt-2 ml-2 font-semibold text-2xl">
                          {playersList.length}{" "}
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 w-full items-center">
                        <PlayerNameLists
                          socketId={socketId}
                          players={playersList}
                        />
                      </div>
                    </div>
                  )}
                </div>
              
              </>
            )}

            {gameStarted && !displayLoader && (
              <div>
                {!gameOver && (
                  <>
                    <div className="font-bold text-2xl text-center">
                      {currentQuestionNo + 1}/5
                    </div>
                    <div className="flex justify-center text-lg text-center text-red-500">
                      <TimeCounter
                        timeCount={timeCount}
                      />
                    </div>
                  </>
                )}

                {currentQuestion && timeCount && (
                  <div>
                    <QuizQuestion
                      onOptionClicked={onOptionClicked}
                      question={currentQuestion.question}
                      options={currentQuestion.options}
                      currentQuestionAnswer={currentQuestionAnswer}
                    />
                  </div>
                )}

                {gameOver && (
                  <div className="flex flex-col gap-4 items-center mx-5 mt-4">
                    <QuizButton
                      text="Play another game"
                      onClick={() => window.location.reload()}
                    />
                  </div>
                )}

                {gameOver && (
                  <div className="flex flex-col gap-4 items-center mx-5 mt-4">
                    <PlayersScoreList
                      socketId={socketId}
                      scores={finalResult}
                    />
                  </div>
                )}

              </div>
            )}
          </div>
        </div>

        <div>
          {!gameStarted && !socketId && !displayLoader && (
            <div className="flex flex-grow flex-row-wrap justify-around py-4 bg-green-100 mt-4">
              <FooterLink />
            </div>
          )}
        </div>
      </div>

    </>
  );
}

export default QuizRoom;
