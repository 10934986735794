


const LangButtonGroups = (props) =>{

    const { onSelectLang, selectedLangType } = props


    return(
            <div className="cursor-pointer flex  flex-wrap pt-3 pb-4 flex-row">
                <div 
                    onClick={() => onSelectLang('en')} 
                    className={`w-24 text-center text-sm uppercase px-4 py-3 rounded block text-black h-101 ${selectedLangType === 'en' ? 'bg-red-300 font-bold' : '' }`}>
                        English
                </div>
                <div 
                    onClick={() => onSelectLang('hin')} 
                    className={`w-24 text-center text-sm uppercase px-4 py-3 rounded block text-black h-10 ${selectedLangType === 'hin' ? 'bg-red-300 font-bold' : '' }`}>
                        हिंदी
                </div>
            </div>
    )
}

export default LangButtonGroups;