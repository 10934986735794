
const LoggerService = {
    log(message) {
      console.log(message);
    },
    error(message) {
      console.error(message);
    },
    warn(message) {
      console.warn(message);
    },
  };
  
  export default LoggerService;
  