
const PrivacyPolicy = () =>{

    return(

        <div className="mx-9 font-sans leading-8">

            <h1>Privacy Policy </h1>

            <div className="my-5">

                <h2>1. Information Collection</h2>
                
                <h3>1.1 Personal Information:</h3>
                <p>When you register an account or participate in the multiplayer real-time quiz, we may collect certain personal information, such as your name and email address. This information is necessary for account creation and game functionality.</p>

                <h3>1.2 Usage Information:</h3>
                <p>We may also collect non-personal information about your interactions with the Website, including but not limited to the quizzes played, scores achieved, and game preferences. This information is used to improve our services and enhance the user experience.</p>
            </div>

            <div className="my-5">

                <h2>2. Information Use</h2>
               
                <h3>2.1 Provision of Services:</h3>
                <p>We use the collected information to provide you with access to the multiplayer real-time quiz and related features. This includes facilitating game sessions, sending invitations, and storing game data for playback.</p>

                <h3>2.2 Communication:</h3>
                <p>We may use your email address to send you important updates, notifications, and information related to the Website. You may opt-out of receiving non-essential communications.</p>

            </div>

            <div className="my-5">
                <h2>3. Information Sharing</h2> 

                <h3>3.1 Third-Party Service Providers:</h3>
                <p> We may engage trusted third-party service providers to assist with the operation and maintenance of the Website. These providers may have access to your personal information but are bound by confidentiality obligations and may only use the information for authorized purposes.</p>

                <h3>3.2 Legal Requirements:</h3>
                <p> We may disclose your personal information if required to do so by law or in response to valid legal requests, such as subpoenas or court orders. We will also disclose information when necessary to protect our rights, enforce our Terms and Conditions, or respond to fraudulent activities or security threats.</p>

            </div>

            <div className="my-5">
                <h3>Data Security</h3>

                <h2>4.1 Security Measures:</h2>
                <p>We implement reasonable security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information. However, no method of data transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.</p>

                <h2>4.2 Retention:</h2>
                <p> We retain your personal information for as long as necessary to fulfill the purposes outlined in this Policy, unless a longer retention period is required or permitted by law.</p>

            </div>
                      
            <div className="my-5">
                <h3>Children's Privacy</h3>
                <p>The Website is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under the age of 13. If we become aware that we have inadvertently collected personal information from a child under the age of 13, we will promptly delete the information from our records.</p>      
            </div>       

            <div className="my-5">
                <h3>Changes to this Privacy Policy</h3>
                <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised Policy on the Website. We encourage you to review this Policy periodically to stay informed about how we collect, use, and protect your information.</p>
            </div>

            <div className="my-5">
            </div>
            
            <div className="my-5">
                <h3>Contact Us</h3>
                <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us through Contact us page..
                    By using the Website, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy.
                </p>
            </div>

        </div>
    )
}

export default PrivacyPolicy;