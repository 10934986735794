import { getRootDomain } from "../utils/urlUtils";


const NotFound = () => {

    const onClickBack = () => {
        let rootDomain = getRootDomain(window.location.href)
        if(!rootDomain.includes('http')){
            rootDomain = 'http://' + rootDomain
        }
        window.location.href = rootDomain
    }

    return (
        <div>
            <div className="h-screen flex  justify-center items-center ">
                <div>
                    <button onClick={onClickBack} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out">
                        Back to Home
                    </button>
                </div>
            </div>
        </div>
    )
}

export default NotFound;