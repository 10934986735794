import APP from "../../constants/app";

const AppHeader = () =>{

    return(
        <header className="w-full flex justify-center flex-col fixed h-16 ">
            <h2 className="bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text text-center font-bold xs:text-3xl sm:text-3xl md:text-3xl lg:text-3xl">
                {APP.APP_NAME}
            </h2>
            <p className="text-center">A Real time multi player Quiz game</p>
        </header>
    )
}

export default AppHeader;