import { CountdownCircleTimer } from 'react-countdown-circle-timer'

const TimeCounter = (props) => {

    const { timeCount } = props
console.log(timeCount)
    return (
        <div>
            <CountdownCircleTimer
                isPlaying
                duration={10}
                colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                colorsTime={[7, 5, 2, 0]}
                size={60}
                strokeWidth={4}
            >
            {({ remainingTime }) => timeCount}
            </CountdownCircleTimer>
        </div>
    )
}

export default TimeCounter;