
const GameManual = () => {

  return (
    <>
      <h2 className=" text-xl font-semibold text-teal-800 mb-2">How to play ?</h2>
      <ol className="list-decimal pl-6 font-mono">
        <li className="mb-2">Click on the Play Game button</li>
        <li className="mb-2">Then Enter the name</li>
        <li className="mb-2">Click Create Game</li>
        <li className="mb-2">After that a link will be displayed, Share this link with friends</li>
        <li className="mb-2">Using that link, Others can join the game</li>
        <li className="mb-2">Player who started the game is Admin</li>
        <li className="mb-2">Admin will start the game</li>
        <li className="mb-2">There are 5 questions, each with 4 options</li>
        <li className="mb-2">Each question has 10 seconds to answer</li>
        <li className="mb-2 text-red-500">Don't refresh the page after creating/joined the game</li>
      </ol>
    </>
  )
}

export default GameManual;