const Loader = (props) => {

    const { message } = props
    return (
        <>
            <div className="flex justify-center items-center">
                <div className="flex flex-col">
                    <div className="h-14 w-14 rounded-full animate-ping 
                        border-t-4 border-b-4 border-teal-600
                    ">
                    </div>
                    <p className="text-teal-700">{message}</p>
                </div>
            </div>
        </>
    )
}

export default Loader;