
const TermsConditions = () =>{

    return(
        <>
            <div className="mx-9 font-sans leading-8">

                <h1>Terms and Conditions</h1>

                <div className="my-5">

                    <h2>1. User Conduct</h2>
                    <h3>1.1 Registration:</h3>
                    <p>In order to use the multiplayer real-time quiz features of the Website Quiz Fleet, you may be required to register an account. When registering, you must provide accurate and complete information. You are solely responsible for maintaining the confidentiality of your account information.</p>

                    <h3>1.2 Age Restrictions:</h3>
                    <p>You must be at least 13 years old to use the Website. If you are under the age of 18, you must obtain parental or legal guardian consent before using the Website.</p>

                    <h3>1.3 Prohibited Activities:</h3>
                    <p>You agree not to engage in any of the following activities:</p>
                    <ul>
                        <li>Impersonating any person or entity, or falsely representing your affiliation with any person or entity.</li>
                        <li>Interfering with or disrupting the servers or networks connected to the Website.</li>
                        <li>Attempting to gain unauthorized access to the Website or its related systems or networks.</li>
                        <li>Collecting or storing personal data about other users without their consent.</li>
                        <li>Posting or transmitting any content that is unlawful, harmful, threatening, abusive, obscene, or otherwise objectionable.</li>
                    </ul>

                </div>

                <div className="my-5">

                    <h2>2. Multiplayer Real-Time Quiz</h2>
                    <h3>2.1 Gameplay:</h3>
                    <p>The multiplayer real-time quiz feature allows players to participate in quizzes simultaneously. To start a game, a player enters their name, and the server sends a link to share with other players. Other players can join by clicking on the shared link and entering their name.</p>

                    <h3>2.2 Database Storage:</h3>
                    <p>All information related to the multiplayer real-time quiz, including player names and quiz data, will be stored in the Website's database. By using the Website, you consent to the collection, storage, and processing of this information.</p>

                </div>

                <div className="my-5">
                    <h2>3. Intellectual Property</h2>
                    <h3>3.1 Ownership:</h3>
                    <p>The Website and its contents, including but not limited to text, graphics, logos, and software, are the intellectual property of the Website owner or its licensors. All rights not expressly granted are reserved.</p>

                    <h3>3.2 Limited License:</h3>
                    <p>Subject to your compliance with these Terms, the Website grants you a limited, non-exclusive, non-transferable license to access and use the Website solely for personal, non-commercial purposes.</p>
                </div>

                <div className="my-5">
                    <h2>4. Limitation of Liability</h2>
                    
                    <h3>4.1 Disclaimer:</h3>  
                    <p>The Website and its services are provided on an "as-is" and "as available" basis. The Website owner makes no warranties or representations of any kind, whether express or implied, regarding the operation or availability of the Website.</p> 

                    <h3>4.2 Limitation of Liability:</h3>
                    <p> To the maximum extent permitted by applicable law, the Website owner shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Website or its services.</p>
                </div>

                <div className="my-5">
                    <h2> 5. Modifications and Termination</h2>
                    
                    <h3>5.1 Modifications:</h3>
                    <p> The Website owner reserves the right to modify, suspend, or discontinue any aspect of the Website or its services at any time without notice. The Website owner shall not be liable to you or any third party for any such modifications, suspension, or discontinuation.</p>

                    <h3>5.2 Termination: </h3>
                    <p>The Website owner may terminate your access to the Website or its services at any time for any reason without prior notice or liability.</p>
                </div>

                <div className="my-5">
                    <h2>6. General Provisions</h2> 
                    
                    <h3>6.1 Governing Law:</h3>
                    <p>These Terms shall be governed by and construed in accordance with the laws of India. Any legal action or proceeding arising out of or relating to these Terms shall be exclusively brought in the courts of India, and the specific region within India for such court proceedings shall be at the discretion of the website owner.</p>

                    <h3>6.2 Severability:</h3>
                    <p> If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions shall continue to be valid and enforceable to the fullest extent permitted by law.</p>

                    <h3>6.3 Entire Agreement: </h3>
                    <p>These Terms constitute the entire agreement between you and the Website owner regarding your use of the Website and supersede any prior or contemporaneous agreements, communications, and proposals, whether oral or written.
                        By using the Website, you acknowledge that you have read, understood, and agree to be bound by these Terms and any additional terms and conditions that may apply.
                    </p>

                </div>

            </div>
        </>
    )
}

export default TermsConditions;