
const FooterLink = () => {

    return (
        <>
            <a href="/contact-us" className="text-gray-500">Contact Us</a>
            <a href="/privacy-policy" className="text-gray-500">Privacy & Policy</a>
            <a href="/terms-conditions" className="text-gray-500">Terms & Conditions</a>
        </>
    )
}

export default FooterLink;