import { useEffect, useState } from "react";
import { motion } from "framer-motion";


const PlayersScoreList = (props) => {

    const { scores, socketId } = props

    const [sortedScores, setSortedScores] = useState(null)
    useEffect(() => {
        if (scores) {
            const res = sortArrayByScore(scores)
            setSortedScores(res)
        }
    }, [scores])

    const sortArrayByScore = (arr) => {
        return arr.sort((a, b) => b.score - a.score);
    }


    return (
        <>

            {sortedScores &&
                sortedScores.map((el, index) => {
                    return (
                        <motion.div
                            key={index}
                            className="w-80"
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{
                                duration: 0.8,
                                delay: 0.5,
                                ease: [0, 0.71, 0.2, 1.01]
                            }}
                        >
                            <div  className="flex w-full lg:w-96 md:w-72 bg-red-200 h-10 items-center justify-around text-xl rounded-md">
                                <div className="w-[70%] overflow-hidden inline-block">
                                    <p className="truncate">
                                        {el?.name}
                                    </p>
                                </div>
                                <b>
                                    {el?.id === socketId ? '(you)' : ''}
                                </b>
                                <b>
                                    {el?.score}
                                </b>

                            </div>
                        </motion.div>
                    )
                })
            }
        </>
    )
}

export default PlayersScoreList;