

const PlayerNameLists = (props) =>{

    const { players, socketId } = props

    return(
        <>
            {
                players.map((el, index) =>{

                    return(
                        <div key={index} className="flex w-full lg:w-96 bg-red-200 h-10 items-center justify-around text-xl rounded-md border-black grow">
                            <div className="w-[70%] overflow-hidden inline-block">
                                <p className="truncate">
                                    {el?.name}
                                </p>
                            </div>
                            <b>{el?.id === socketId ? '(you)' : ''}</b>
                        </div>
                    )
                })
            }
        </>
    )
}

export default PlayerNameLists;