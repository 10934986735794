import axios from "axios";
import { useState } from "react";
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";

const ContactUs = () =>{

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            message: '',
          },
    });


    const onSend = async(data) =>{
        console.log(data)
        try {
            
            setLoading(true)
            const res = await axios.post('https://qshare-be.onrender.com/api/v1/message', {
                ...data
            })
            setLoading(false)
            reset()
            toast('Thanks, Message received!')
    
        } catch (error) {
            setLoading(false)
            reset()
            toast('Failed, Try after sometimes!', { type: 'error' })
        }
        
    }

    return(
        <div>
            <ToastContainer />

            <div className="">
                <button onClick={() => navigate('/')}  className="mt-9 ml-10 w-16 p-2 rounded-lg text-white font-semibold text-center cursor-pointer hover:bg-red-400 bg-red-500 ">Back</button>       
            </div>

            <form onSubmit={handleSubmit(onSend)}>
                <div className="flex flex-col gap-2 align-middle items-center ">
                        <div className="text-2xl">Contact us</div>
                    
                    
                        <div className="flex flex-col gap-1">
                            <input 
                                type="text" 
                                placeholder="Your name"
                                className="border border-red-300 rounded-lg px-4 py-2  xs:w-full"
                                {...register('name', { required: 'Required' })}
                            />
                        <div className="text-red-800">
                                {errors.name && <span>{errors.name.message}</span>}
                            </div>

                        </div>
                        <div className="flex flex-col gap-1">
                            <textarea
                                placeholder="Your message"
                                className="border border-red-300 rounded-lg px-4 py-2  xs:w-full"
                                rows="10"
                                col="10"
                                {...register('message', { required: 'Required' })}
                            >    
                            </textarea>
                        <div className="text-red-800">
                            {errors.message && <span>{errors.message.message}</span>}
                            </div> 
                        </div>
                        <div>
                            <button disabled={loading} type="submit" className="h-10 w-24 px-2 bg-red-500 rounded-lg font-semibold text-white disabled:bg-gray-300">
                                {loading ? 'Sending...' : 'Send' }
                            </button>
                        </div>


                </div>
            </form>
        </div>
    )
}

export default ContactUs;