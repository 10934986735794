const QuizButton = (props) => {
  const { text, onClick } = props;

  return (
    <>
      <button
        onClick={onClick}
        className="w-auto bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-6 rounded-full shadow-md mb-6 transform transition duration-300 hover:scale-105">
          {text}
      </button>
    </>
  );
};

const GreenButton = (props) => {
  const { text, onClick, disabled } = props;

  return (
    <>
      <button
        disabled={disabled || false}
        onClick={onClick}
        className="px-4 py-2 bg-green-500 xs:w-4 text-white font-semibold rounded"
      >
        {text}
      </button>
    </>
  );
};

const OutLined = () => {

  return (
    <>
    </>
  )
}

export { QuizButton, GreenButton, OutLined };
