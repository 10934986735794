export function getRootDomain(url = window.location.href) {
    
    // Remove protocol (http:// or https://)
    let withoutProtocol = url.replace(/^(https?:|)\/\//, '');

    // Remove www subdomain if present
    let withoutSubdomain = withoutProtocol.replace(/^(www\.|)/, '');

    // Extract the root domain
    let rootDomainMatch = withoutSubdomain.match(/^[^\/]+/);
    
    // Check if there is a match
    if (rootDomainMatch) {
        return rootDomainMatch[0];
    }

    return null; // Return null if no root domain is found
}


export function redirectToHome(){
   
    let rootDomain = getRootDomain()
    if(!rootDomain.includes('http://')){
        rootDomain = 'http://' + rootDomain
    }
    window.location.href = rootDomain
}